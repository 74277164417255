.hidden {
    display: none !important;
}

/*#main {
  height: 100%;
  width: 100%;
}*/

#call,
#videochat {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#call,
#videochat {
    background-color: #000000;
}

#videochat-sharing-screen {
    background-color: #ffffff;
}

.videochat-sharing-screen-active {
    background-color: #2d2b2b !important;
}

#videochat-sharing-screen i {
    font-size: 20px;
    color: #474747;
}

.videochat-sharing-screen-icon-active {
    color: white !important;
}



#login-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#login-image {
    margin-top: 10px;
    height: 200px;
    width: 320px;
}

#login-caption,
#login-loader,
.videochat-stream-loader {
    height: 32px;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#login-caption,
#login-loader-text {
    line-height: 30px;
}

#login-buttons-container {
    margin-bottom: 15px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    #login-buttons-container .login-button {
        height: 50px;
        width: 270px;
        max-height: 90%;
        margin: 5px 0;
        border-radius: 25px;
        font-size: 20px;
        color: #ffffff;
    }



#call-select-users {
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#call-buttons-container,
#videochat-buttons-container {
    width: 320px;
    height: 50px;
    position: fixed;
    bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 200;
}

    #call-buttons-container .call-button,
    #videochat-buttons-container .videochat-button {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }

#select-header {
    color: #1198d4;
    font-size: 20px;
    padding: 20px;
}

.select-user {
    width: 150px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: 5px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
}

    .select-user span {
        height: 18px;
        width: 100px;
        display: block;
    }

    .select-user input {
        height: 30px;
        width: 20px;
        display: block;
        margin: 0;
    }	


.videochat-stream-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.videochat-stream-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.videochat-stream {
    height: 100%;
    width: 100%;
    object-fit: cover;
    visibility: hidden;
}







#call-start {
    background: forestgreen url("../../../theme/assets/images/call.svg") no-repeat center;
}

#videochat-mute-unmute {
    background: #0000ff url("../../../theme/assets/images/mic.svg") no-repeat center;
}

    #videochat-mute-unmute.muted {
        background: #0000ff url("../../../theme/assets/images/mic_off.svg") no-repeat center;
    }

#videochat-stop-call {
    background: #ff0000 url("../../../theme/assets/images/call_end.svg") no-repeat center;
}

#videochat-switch-camera {
    background: #ffa500 url("../../../theme/assets/images/switch_video.svg") no-repeat center;
}	




.Alice-bg-color {
    background-color: #34ad86;
}

.Bob-bg-color {
    background-color: #077988;
}

.Ciri-bg-color {
    background-color: #13aaae;
}

.Dexter-bg-color {
    background-color: #056a96;
}

#videochat-streams {
    height: 100%;
    width: 100%;
    max-width: 1024px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 50%;
}

    #videochat-streams.grid-2-1,
    #videochat-streams.grid-2-2 {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 50%;
    }

        #videochat-streams.grid-2-1 > #videochat-local-stream-container {
            grid-column: span 2;
        }



#snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 40px;
    transform: translate(-50%);
}
#call-modal-icoming {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* visibility: hidden; */
    height: 125px;
    width: 350px;
    background-color: #dddddd;
    border-radius: 4px;
    box-shadow: 0px 0px 30px 3px #20484a;
    padding: 15px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
}

    #call-modal-icoming .call-modal-header {
        color: black;
        font-size: 20px;
        padding: 10px 0;
    }

    #call-modal-icoming .call-modal-footer {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    #call-modal-icoming .call-modal-button {
        height: 40px;
        width: 100px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
        border: none !important;
        outline: none !important;
    }

    #call-modal-icoming #call-modal-reject {
        background-color: #dc3545;
    }

    #call-modal-icoming #call-modal-accept {
        background-color: #4eb2b8;
    }

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#call-modal-icoming.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s;
    animation: fadein 0.5s, fadeout 0.5s;
}

@-webkit-keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 40px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 40px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        top: 40px;
        opacity: 1;
    }

    to {
        top: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        top: 40px;
        opacity: 1;
    }

    to {
        top: 0;
        opacity: 0;
    }
}
