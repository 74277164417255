
.btn-patient-case {
    direction: rtl;
    z-index: 999;
    background: #FFC107;
    box-shadow: 0 3px 6px rgb(255 193 7 / 29%), 0 1px 3px rgb(255 193 7 / 22%);
    line-height: 28px;
    border: none;
    display: flex;
    color: #fff !important;
    will-change: background;
    transition: background .3s;
    font-size: 13px;
    font-weight: 400;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

    .btn-patient-case i {
        font-size: 25px;
    }

#pateint-case-wrapper {
    direction: rtl;
    /*position: fixed;*/
    /*bottom: 90px;*/
    /*left: 20px;*/
    background-color: #e7e7e7;
    /*width: 400px;*/
    /*max-width: calc(100% - 40px );*/
    border-radius: 10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    z-index: 99;
    border: 1px solid #c7c7c7;
    border-bottom-left-radius: 0;
}

.noteing-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    background-color: #fff;
    /*max-height: 350px;*/
    overflow-y: auto;
}

.noteing-body {
    padding: 10px 10px 40px 10px;
}

.noteing-header {
    padding: 5px;
    background: #4eb2b8;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.notes-right-wrapper {
    max-width: 95%;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
}

.note-right {
    background: #f4f4f4;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    margin-left: auto;
    width: fit-content;
    border-bottom-right-radius: 0;
    direction: rtl;
    text-align: justify;
    text-align-last: right;
    line-height: 30px;
    color: #000000;
    font-size: 13px;
    font-weight: 300;
    word-spacing: -2px;
    white-space: pre-wrap;
}

.noteing-date {
    text-align: center;
    margin-bottom: 10px;
}

    .noteing-date p {
        margin: 0;
        font-size: 11px;
    }

.chat-textbox {
    background: #ffffff !important;
    border: none;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    padding-left: 50px;
    font-size: 14px;
    font-weight: 300;
    min-height: 45px;
    border-top: 1px solid #c7c7c7;
}

.btn-send-pm {
    position: absolute;
    top: 5px;
    padding: 0;
    left: 5px;
    width: 35px;
    height: 35px;
    font-size: 15px;
    color: #fff !important;
    background: #4eb2b8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 300ms;
}

    .btn-send-pm:hover {
        background: #5aced4;
    }

.user-avatar {
    position: relative;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    filter: brightness(1.1);
    margin: 5px 0
}

.user-info-brief h2 {
    width: 100%;
    margin: 0;
    line-height: 25px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    text-align: right;
    direction: rtl;
}

.user-info-brief i {
    color: #fff;
}



