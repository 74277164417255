.side-bar {
  z-index: 999 !important;
  left: 0px !important;
}

.side-bar-veil {
  z-index: 999 !important;
}

aside.ps-sidebar-root {
  z-index: 9999999 !important;
}

.Toastify__toast-theme--light {
  color: #ffffff !important;
}

.Toastify__toast {
  color: #ffffff !important;
}

.Toastify__progress-bar-theme--light {
  background: #fff !important;
}

.Toastify__progress-bar--bg {
  background: #fff !important;
}

.Toastify__toast-icon svg {
  fill: #fff !important;
}

a.calendarKbhClassName1.calendarKbhClassName2 {
  background: #368f96 !important;
  color: white !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #dfede4;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #dfede4;
}

.btn {
  font-size: 14px;
  font-weight: 500;
}

.btn-primary {
  color: #fff;
  background-color: #3d99a1;
  border-color: #17a2b8;
}

.btn-primary:hover {
  color: #000000;
  background-color: #dfeded;
  border-color: #d4d4d4;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #000000;
  background-color: #dfeded;
  border-color: #d4d4d4;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0c575d;
  border-color: #17a2b8;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #000000;
  background-color: #b0e4ec;
  border-color: #17a2b8;
}

.fc-header-toolbar
  .btn-group
  > .btn:not(:last-child):not(:first-child):not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fc-header-toolbar .btn-group > .btn:is(:last-child) {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.fc-header-toolbar .btn-group > .btn:is(:first-child) {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.fc .fc-toolbar-title {
  font-size: 15px !important;
}

a.fc-col-header-cell-cushion {
  font-size: 13px;
  font-weight: 500;
}

th.fc-col-header-cell.fc-day {
  background-color: #dfeded;
}

.kbh-calendar-container {
  direction: ltr;
  width: 100%;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px #acc3c7;
}

.fc .fc-view-harness {
  /* height: calc(100vh - 200px); */
}

.fc .fc-daygrid-day-number {
  padding: 2px 5px;
  position: relative;
  z-index: 4;
  line-height: 1.1;
  font-weight: 500;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
}

.fc .fc-daygrid-day-frame {
}

.fc-direction-rtl .fc-daygrid-more-link {
  float: right;
  width: 100%;
  background: #ff9d00;
  color: #fff !important;
  animation: glowing 1500ms infinite;
  padding: 6px;
  text-align: center;
}

.fc-list-day-cushion.table-active {
  font-weight: 400;
}

.fc-timegrid-col-misc {
  text-align: center;
}

td.fc-day.fc-daygrid-day {
  /* height: 75px; */
}
