ul,
li {
  list-style: none;
}
.dashboard {
  padding: 10px;
}

.dashboard .card {
  background-color: #fff;
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 20px 10px;
  overflow: hidden;
  text-align: center;
  transition: transform 300ms;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.04), 0 -1px 4px rgba(19, 51, 116, 0.09);
}

.dashboard .card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background: none !important;
  padding-top: 0;
}
.dashboard .card-header .right {
  color: #bbbbca;
}
.dashboard .card-header h1 {
  display: inline-block;
  margin: 0;
  font-size: 17px;
  font-weight: 400;
}
.dashboard .card-header .header-container {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  align-items: end;
}
.dashboard .card-header .header-container span {
  color: #bbbbca;
  position: absolute;
  margin-left: 9rem;
  margin-top: 0.3rem;
}
.dashboard .card-header select {
  height: fit-content;
  border: none;
  background: none;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.dashboard .card .card-body {
  display: grid;
  gap: 15px;
  padding: 10px;
}
.dashboard .card .appointment {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
}
.dashboard .card .appointment:last-child {
  border: none;
}
.dashboard .card .appointment .image {
  grid-area: profile;
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
}
.dashboard .card .appointment .name {
  grid-area: name;
  font-weight: 500;
  text-align: right;
  padding-bottom: 1px;
}
.dashboard .card .appointment .title {
  grid-area: title;
  font-size: 0.8rem;
  color: #9695af;
  text-align: right;
  padding-bottom: 10px;
}
.dashboard .card .appointment .date {
  grid-area: date;
  font-size: 0.8rem;
  text-align: right;
}
.dashboard .card .appointment .date i {
  color: #4eb2b8;
}
.dashboard .card .appointment .time {
  grid-area: time;
  font-size: 0.8rem;
  text-align: right;
}
.dashboard .card .appointment .time i {
  color: #4eb2b8;
}
.dashboard .card .appointment .check {
  grid-area: check;
  font-size: 1.6rem;
  align-self: center;
  color: #4eb2b8;
}
.dashboard .card .appointment .times {
  grid-area: times;
  font-size: 1.6rem;
  align-self: center;
  color: #9b9b9b;
}
.dashboard .card .chart-container {
  height: 17rem;
}
@media screen and (max-width: 650px) {
  .dashboard .card .chart-container {
    height: 10rem;
  }
}
.dashboard .card .news-list .news {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-areas: "title arrow" "subtitle arrow";
  gap: 0.5rem;
}
.dashboard .card .news-list .news .title {
  grid-area: title;
  font-weight: 700;
  color: #0c0a3d;
}
.dashboard .card .news-list .news .subtitle {
  grid-area: subtitle;
  color: #9594ae;
}
.dashboard .card .news-list .news .fa-caret-right {
  grid-area: arrow;
  align-self: center;
}
.dashboard .card .news-list .news hr {
  width: 100%;
  border: none;
  background-color: #bbbbca;
  height: 1px;
}
.dashboard .card .news-list .more {
  text-align: center;
}
.dashboard .card .news-list .more a {
  color: #7e7fe8;
  font-weight: bold;
}
.dashboard .card.alert {
  background: linear-gradient(
    90deg,
    rgb(54, 143, 151) 0%,
    rgb(60, 153, 161) 50%,
    rgba(78, 178, 184, 1) 100%
  );
  color: #fff;
}
.dashboard .current-prescription-container {
  display: grid;
  grid-template-rows: 1fr max-content;
  gap: 10px;
}
.dashboard .current-prescription-container ul {
  display: grid;
  row-gap: 0.75rem;
}

.prescription-item {
  display: grid;
  grid-template-areas: "dot title" "dot description";
  grid-template-columns: min-content 1fr;
  column-gap: 1.5rem;
}
.prescription-item .dot {
  grid-area: dot;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  align-self: center;
}
.prescription-item .dot.gray {
  background-color: #bccbf5;
  box-shadow: 2px 2px 10px 0px #bccbf5;
}
.prescription-item .dot.violet {
  background-color: #7e7fe8;
  box-shadow: 2px 2px 10px 0px #7e7fe8;
}
.prescription-item .title {
  grid-area: title;
  color: #0c0a3d;
  font-weight: 800;
}
.prescription-item .description {
  grid-area: description;
}

.notifications ul {
  display: grid;
  gap: 1.5rem;
}

.notifications .more {
  text-align: center;
}
.notifications .notification {
  display: grid;
  grid-template-areas: "dot title date";
  grid-template-columns: min-content 1fr max-content;
  align-items: center;
  gap: 10px;
}
.notifications .notification .dot {
  grid-area: dot;
  width: 23px;
  height: 23px;
  border-radius: 5px;
  color: #fff;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications .notification .dot.green {
  background-color: #4baeb4;
}
.notifications .notification .dot.red {
  background-color: #dc3545;
}
.notifications .notification .dot.blue {
  background-color: #ffc107b0;
}
.notifications .notification .title {
  grid-area: title;
  color: #3c3c3c;
  font-weight: 400;
  text-align: right;
}
.card.alert span {
  font-size: 12px;
}
.card.alert span b {
  font-weight: 500 !important;
}

.notifications .notification .date {
  grid-area: date;
  font-size: 11px;
  color: #a2a2a2;
  font-family: sans-serif;
  font-weight: 600;
}

.recent-results.card {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.dashboard .club-widget-card p {
  font-size: 12px;
  color: #3c3c3c;
  z-index: 9;
  position: relative;
}
.dashboard .club-widget-card i {
  z-index: 9;
  position: relative;
}
.dashboard .club-widget-card img {
  height: 45px;
  width: 45px;
  z-index: 9;
  position: relative;
  margin-bottom: 10px;
}
.dashboard .club-widget-card {
  box-shadow: 0 8px 8px -9px rgb(112 112 112);
  background: #f8f8f8;
  opacity: 1;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  height: 100%;
}
.dashboard .club-widget-card:after {
  content: " ";
  width: 955px;
  height: 965px;
  position: absolute;
  bottom: 78%;
  right: -260px;
  border-radius: 35%;
  background: white;
  z-index: 0;
  transform: rotate(-45deg);
}
.dashboard .blog-img-holder {
  height: 130px;
}
.dashboard .card-blog-home .card-title {
  margin: 0;
}
.top,
.bottom,
.middle {
  padding: 10px 0;
}
.category-brief .more a {
  display: flex;
  text-align: center;
  justify-content: center;
  color: #818181;
  height: 35px;
  align-items: center;
  border-radius: 0 0 5px 5px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255 255 255 / 8%) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.labels-status .is-waiting {
  padding: 10px 20px;
  background: #ffc107f2;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgb(255 203 47 / 28%), 0 -1px 4px rgb(255 203 47 / 33%);
  font-size: 13px;
  font-weight: 400;
  /*width: 130px;*/
  display: flex;
  justify-content: center;
  /*display: inline-block;*/
  align-items: center;
}
.labels-status .is-active {
  padding: 10px 20px;
  background: #3a969e;
  box-shadow: 0 5px 20px rgb(59 150 157 / 42%), 0 -1px 4px rgb(60 150 157 / 32%);
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  /*width: 130px;*/
  display: flex;
  justify-content: center;
  /*display: inline-block;*/
  align-items: center;
}
.labels-status .not-active {
  padding: 10px 20px;
  background: #dc3545e3;
  box-shadow: 0 5px 20px rgb(220 53 69 / 36%), 0 -1px 4px rgb(220 53 69 / 28%);
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  /* width: 80px; */
  display: flex;
  justify-content: center;
  /*display: inline-block;*/
  align-items: center;
}
/*.labels-status {
    display:inline-block;
}*/
.labels-status .not-active span {
  font-size: 11px;
  display: flex;
  margin-right: 5px;
}
.follow-us-on-social {
}
.follow-us-on-social p {
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #616161;
}
/*-------------social media-------------------*/
.effect {
  width: 100%;
  padding: 50px 0px 70px 0px;
}
.effect h2 {
  font-weight: 500;
  font-size: 20px;
  color: #5d5d5d;
  margin-top: 15px;
}
.effect:nth-child(2) {
  margin-top: 15px;
}

.effect .buttons {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.effect a:first-child {
  margin-right: 0px;
}

/*common link styles !!!YOU NEED THEM*/
.effect {
  /*display: flex; !!!uncomment this line !!!*/
}
.effect a {
  text-decoration: none !important;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 20px;
  font-size: 22px;
  overflow: hidden;
  position: relative;
}
.effect a i {
  position: relative;
  z-index: 3;
}
.effect a.fb {
  background-color: #3b5998;
  box-shadow: 0 5px 20px rgb(59 89 152 / 31%), 0 -1px 4px rgb(59 89 152 / 33%);
}
.effect a.tw {
  background-color: #00aced;
}
.effect a.cast {
  background-color: #ff6f00;
}
.effect a.g-plus {
  background-color: #dd4b39;
}
.effect a.dribbble {
  background-color: #ea4c89;
}
.effect a.pinterest {
  background-color: #cb2027;
  box-shadow: 0 5px 20px rgb(203 32 39 / 31%), 0 -1px 4px rgb(203 32 39 / 33%);
}
.effect a.insta {
  background-color: #bc2a8d;
  box-shadow: 0 5px 20px rgb(188 42 141 / 31%), 0 -1px 4px rgb(188 42 141 / 33%);
}
.effect a.in {
  background-color: #007bb6;
}
.effect a.vimeo {
  background-color: #1ab7ea;
}

/* aeneas effect */
.effect.aeneas a {
  transition: transform 0.4s linear 0s, border-top-left-radius 0.1s linear 0s,
    border-top-right-radius 0.1s linear 0.1s,
    border-bottom-right-radius 0.1s linear 0.2s,
    border-bottom-left-radius 0.1s linear 0.3s;
}
.effect.aeneas a i {
  transition: transform 0.4s linear 0s;
}
.effect.aeneas a:hover {
  transform: rotate(360deg);
  border-radius: 50%;
}
.effect.aeneas a:hover i {
  transform: rotate(-360deg);
}

/* jaques effect */
.effect.jaques a {
  transition: border-top-left-radius 0.1s linear 0s,
    border-top-right-radius 0.1s linear 0.1s,
    border-bottom-right-radius 0.1s linear 0.2s,
    border-bottom-left-radius 0.1s linear 0.3s;
}
.effect.jaques a:hover {
  border-radius: 50%;
}

/* egeon effect */
.effect.egeon a {
  transition: transform 0.2s linear 0s, border-radius 0.2s linear 0.2s;
}
.effect.egeon a i {
  transition: transform 0.2s linear 0s;
}
.effect.egeon a:hover {
  transform: rotate(-90deg);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.effect.egeon a:hover i {
  transform: rotate(90deg);
}

/* claudio effect */
.effect.claudio a {
  transition: transform 0.2s linear 0s, border-radius 0.2s linear 0s;
}
.effect.claudio a:hover {
  transform: scale(1.2);
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
}

/* laertes effect */
.effect.laertes a {
  transition: all 0.2s linear 0s;
}
.effect.laertes a i {
  transition: all 0.2s linear 0s;
}
.effect.laertes a:hover {
  border-radius: 50%/20%;
}
.effect.laertes a:hover i {
  transform: scale(1.1);
  text-shadow: 0 0 12px rgba(33, 33, 33, 0.6);
}
.badge-coming-soon {
  padding: 5px 10px;
  background: #ffc414;
  color: #fff;
  border-radius: 0;
  box-shadow: 0 5px 20px rgb(255 203 47 / 28%), 0 -1px 4px rgb(255 203 47 / 33%);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  height: 20px;
  left: 0;
  right: 0;
  bottom: 0;
}
.w-unset {
  width: unset !important;
}
.dashboard .current-point h4 {
  font-size: 14px;
  width: 100% !important;
  font-weight: 300;
  color: white;
  background: #ffc107;
  display: flex;
  width: fit-content;
  margin: 15px auto;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(255 193 7 / 29%), 0 1px 3px rgb(255 193 7 / 22%);
  text-align: center;
  justify-content: center;
}
.dashboard .alert .btn-reserve-offline {
  background: #ffd555;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
.notifications-page li {
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.notifications-page ul {
  gap: 1rem !important;
}
@keyframes glowing {
  0% {
    background-color: #ff9d00;
    box-shadow: 0 0 3px #ff9d00;
  }
  50% {
    background-color: #ff9d00;
    box-shadow: 0 0 15px #ff9d00;
  }
  100% {
    background-color: #ff9d00;
    box-shadow: 0 0 3px #ff9d00;
  }
}
#root {
  height: 100vh !important;
  overflow: hidden !important;
}
